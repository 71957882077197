export const TOKEN_ACTIONS = {
	TEMPORARY: 'Temporário',
	RECURRENT: 'Recorrente',
	GIVE_ACCESS: 'Grant',
};

export const AUTH_TYPES = {
	INVITE: 'invite',
	REGISTER: 'register',
	CHECKIN: 'checkin',
	GRANT: 'grant',
	QUALIFY: 'qualify',
	ACTIVATE: 'activate',
};

export const AUTH_TYPES_BR = {
	[AUTH_TYPES.INVITE]: 'Convite',
	[AUTH_TYPES.REGISTER]: 'Registro',
	[AUTH_TYPES.CHECKIN]: 'Check-in',
	[AUTH_TYPES.GRANT]: 'Concessão',
	[AUTH_TYPES.QUALIFY]: 'Qualificação',
	[AUTH_TYPES.ACTIVATE]: 'Ativação',
};

export const TOKEN_TYPES = {
	TEMPORARY: 'Temporário',
	RECURRENT: 'Recorrente',
	GRANT: 'Grant',
};

export const TOKEN_STATUS = {
	ACTIVE: 'active',
	INVITATION_SENT: 'invitation_sent',
	INACTIVE: 'inactive',
	PENDING: 'pending',
	REJECTED: 'rejected',
	EXPIRED: 'expired',
	REVOKED: 'revoked',
};

export const TOKEN_STATUS_TEXT = {
	ACTIVE: 'Ativo',
	INVITATION_SENT: 'Convite enviado',
	INACTIVE: 'Revogado',
	PENDING: 'Pendente',
	REJECTED: 'Rejeitado',
	EXPIRED: 'Expirado',
	REVOKED: 'Revogado',
};

export const getTokenStatusColor = (tokenStatus: string) => {
	switch (tokenStatus) {
		case TOKEN_STATUS.ACTIVE:
			return 'success';
		case TOKEN_STATUS.INVITATION_SENT:
			return 'info';
		case TOKEN_STATUS.INACTIVE:
			return 'danger';
		case TOKEN_STATUS.PENDING:
			return 'info';
		case TOKEN_STATUS.REJECTED:
			return 'danger';
		case TOKEN_STATUS.EXPIRED:
			return 'secondary';
		case TOKEN_STATUS.REVOKED:
		default:
			return 'primary';
	}
};

export const getTokenStatusText = (tokenStatus: string) => {
	switch (tokenStatus) {
		case TOKEN_STATUS.ACTIVE:
			return TOKEN_STATUS_TEXT.ACTIVE;
		case TOKEN_STATUS.INVITATION_SENT:
			return TOKEN_STATUS_TEXT.INVITATION_SENT;
		case TOKEN_STATUS.INACTIVE:
			return TOKEN_STATUS_TEXT.INACTIVE;
		case TOKEN_STATUS.PENDING:
			return TOKEN_STATUS_TEXT.PENDING;
		case TOKEN_STATUS.REJECTED:
			return TOKEN_STATUS_TEXT.REJECTED;
		case TOKEN_STATUS.EXPIRED:
			return TOKEN_STATUS_TEXT.EXPIRED;
		case TOKEN_STATUS.REVOKED:
			return TOKEN_STATUS_TEXT.REVOKED;
		default:
			return 'Desconhecido';
	}
};

export const USER_ROLES_LOWER = {
	GUEST: 'guest',
	CONCIERGE: 'concierge',
	CONTRACT_MANAGER: 'contract_manager',
	SPOT_MANAGER: 'spot_manager',
	ACCESS_MANAGER: 'access_manager',
	STAFF: 'staff',
	SYSADMIN: 'sys_admin',
};

export const USER_TAGS_LOWER = {
	OWNER: 'owner',
	PARTY: 'party',
	SERVICE: 'service',
	RENT: 'rent',
	VISITOR: 'visitor',
	FAMILY: 'family',
	DELIVERY: 'delivery',
	REALTOR: 'realtor',
	STAFF: 'staff',
};

export const USER_TAGS_BR = {
	[USER_TAGS_LOWER.OWNER]: 'Proprietário',
	[USER_TAGS_LOWER.PARTY]: 'Festa',
	[USER_TAGS_LOWER.SERVICE]: 'Serviço',
	[USER_TAGS_LOWER.RENT]: 'Aluguel',
	[USER_TAGS_LOWER.VISITOR]: 'Visitante',
	[USER_TAGS_LOWER.FAMILY]: 'Familiar',
	[USER_TAGS_LOWER.DELIVERY]: 'Entrega',
	[USER_TAGS_LOWER.REALTOR]: 'Corretor',
	[USER_TAGS_LOWER.STAFF]: 'Equipe',
};

export const USER_ROLES_BR = {
	GUEST: 'Convidado',
	CONCIERGE: 'Porteiro',
	CONTRACT_MANAGER: 'Contract manager',
	SPOT_MANAGER: 'Spot manager',
	STAFF: 'Equipe',
	ACCESS_MANAGER: 'Access manager',
	SYSADMIN: 'Administrador do sistema',
};

export const REASON_ACCESS = {
	PARTY: 'Festa',
	SERVICE: 'Servico',
	GUEST: 'Convidado',
}

export const getReasonAccessText = (reason: string) => {
	switch (reason) {
		case "owner":
			return 'Proprietário';
		case "service":
			return 'Serviço';
		case "staff":
			return 'Equipe';
		case "visitor":
			return 'Visitante';
		case "family":
			return 'Familiar';
		case "party":
			return 'Festa';
		case "rent":
			return 'Aluguel';
		case "realtor":
			return 'Corretor';
		case "delivery":
			return 'Tele Entrega';
		case 'quick_service':
			return 'Serviço rápido';
		case 'domestic_service':
			return 'Serviço doméstico';
		default:
			return 'Não identificado';
	}
};

export const getTypeAccessText = (type: string) => {
	switch (type) {
		case "UNIQUE":
			return "Único";
		case "RECURRENT":
			return "Recorrente";
		default:
			return "Não identificado";
	}
}

export const getCanGrantAccessText = (grant: string) => {
	switch (grant) {
		case "YES":
			return "Sim";
		case "NO":
			return "Não";
		default:
			return "Não identificado";
	}
}

export const getIsConciergeText = (isConcierge: string) => {
	switch (isConcierge) {
		case "YES":
			return "Sim";
		case "NO":
			return "Não";
		default:
			return "Não identificado";
	}
}

export const getTokenTypeDescription = (selectedOption: string) => {
	switch (selectedOption) {
		case TOKEN_ACTIONS.TEMPORARY:
			return 'Acesso expira depois de usado.';
		case TOKEN_ACTIONS.RECURRENT:
			return 'Acessos ilimitados por um período determinado.';
		case TOKEN_ACTIONS.GIVE_ACCESS:
			return 'Indicado para acessos por um período determinado.';
		default:
			return '';
	}
};
export const getDefaultLabel = (selectedOption: string) => {
	switch (selectedOption) {
		case REASON_ACCESS.PARTY:
			return 'Festa';
		case REASON_ACCESS.GUEST:
			return 'Convidado';
		case REASON_ACCESS.SERVICE:
			return 'Serviço';
		default:
			return '';
	}
};


export const getFirstLabelForSelectedOption = (selectedOption: string) => {
	switch (selectedOption) {
		case TOKEN_ACTIONS.TEMPORARY:
			return 'Reunião';
		case TOKEN_ACTIONS.RECURRENT:
			return 'Serviço';
		case TOKEN_ACTIONS.GIVE_ACCESS:
			return 'Aluguéis';
		default:
			return '';
	}
};

export const getSecondLabelForSelectedOption = (selectedOption: string) => {
	switch (selectedOption) {
		case TOKEN_ACTIONS.TEMPORARY:
			return 'Visitante';
		case TOKEN_ACTIONS.RECURRENT:
			return 'Eventos';
		case TOKEN_ACTIONS.GIVE_ACCESS:
			return 'Familiares';
		default:
			return null;
	}
};

export const getThirdLabelForSelectedOption = (selectedOption: string) => {
	switch (selectedOption) {
		case TOKEN_ACTIONS.GIVE_ACCESS:
			return 'Funcionário';
		default:
			return null;
	}
};