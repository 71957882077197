import { orderBy } from "lodash";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../contexts/user-context";
import { getBuildingSpotsList } from "../../../../services/spot/spot-service";
import { getAllTokensByBuildingId, getTokensIssuedForUserGrantAsSpotList } from "../../../../services/token/token-service";
import { SpotList } from "../../../../type/spot";
import Card, { CardBody } from "../../../bootstrap/Card";
import Select from "../../../bootstrap/forms/Select";
import Option from '../../../bootstrap/Option';
import { TOKEN_STATUS, getReasonAccessText } from "../../token/token-activation-form/blocks/constants";

interface TargetSpotTokenActivationProps {
    buildingId: number;
    setTargetSpot: React.Dispatch<React.SetStateAction<string[]>>;
    targetSpot: string[];
    reasonAccess: string;
    canGrantAccess: string;
}

export function TargetSpotTokenActivation({ buildingId, setTargetSpot, targetSpot, reasonAccess, canGrantAccess }: TargetSpotTokenActivationProps) {
    const { user } = useContext(UserContext);
    const [spots, setSpots] = useState<SpotList>([]);

    useEffect(() => {
        const getSpotsOwner = async () => {
            const response = await getTokensIssuedForUserGrantAsSpotList(user.id)
            setSpots(response)
        }

        const getSpots = async () => {
            const allSpotsOfBuildingToken = await getBuildingSpotsList(buildingId)

            const allTokensOfBuildingToken = await getAllTokensByBuildingId(buildingId);

            const allNotAvailableSpotIds = allTokensOfBuildingToken.results
                .filter((token) => token.status === TOKEN_STATUS.ACTIVE || token.status === TOKEN_STATUS.PENDING)
                .map((token) => token.spotId)

            if (allNotAvailableSpotIds.length > 0) {
                const arrayEmptySpots = allSpotsOfBuildingToken.filter((spot) => !allNotAvailableSpotIds.includes(spot.id));

                setSpots(arrayEmptySpots);
            }
        }

        if (reasonAccess !== "owner" && canGrantAccess === "YES") {
            getSpotsOwner()
        }
        else {
            getSpots()
        }
        // eslint-disable-next-line
    }, [reasonAccess, canGrantAccess])

    const handleMultiSetTargetSpot = (e: { target: { value: string; }; }) => {
        const selectedSpotId = e.target.value
        const index = targetSpot.indexOf(selectedSpotId)

        if (index !== -1) {
            const newArray = [...targetSpot];
            newArray.splice(index, 1);
            setTargetSpot(newArray);
        } else {
            setTargetSpot([...targetSpot, selectedSpotId]);
        }
    }

    const handleSetTargetSpot = (e: { target: { value: string; }; }) => {
        const selectedSpotId = [e.target.value]
        setTargetSpot(selectedSpotId)
    }

    return (
        <Card>
            <CardBody>
                <div className='row'>
                    {spots.length > 0 && reasonAccess !== "owner"  &&
                        <>
                            <div className='fw-bold fs-5 me-2 d-flex justify-content-center'>
                                Qual a Unidade?
                            </div>
                            <div className='d-flex justify-content-center mt-3'>
                                <Select
                                    ariaLabel='Board select'
                                    placeholder='Selecione a Unidade'
                                    onChange={handleSetTargetSpot}>
                                    {spots &&
                                        orderBy(spots, ['name'], ['asc']).map((spot) => (
                                            <Option key={spot.id} value={spot.id}>
                                                {spot.name}
                                            </Option>
                                        ))
                                    }
                                </Select>
                            </div>
                        </>
                    }

                    {spots.length <= 0 && reasonAccess !== "owner"  && reasonAccess !== "staff" &&
                        <>
                            <div className='fw-bold fs-5 me-2 d-flex justify-content-center text-center'>
                                Nenhum token ativo do tipo Proprietário encontrado.
                            </div>
                            <small className="text-center text-primary fs-6">
                                Para continuar a ativação de um {getReasonAccessText(reasonAccess)} como alguém que possa autorizar em seu nome,
                                você precisa ter um token ativo do tipo Proprietário para algum Spot.
                            </small>
                        </>
                    }

                    {spots.length > 0 && reasonAccess === "owner" &&
                        <>
                            <div className='fw-bold fs-5 me-2 d-flex justify-content-center'>
                                Qual a Unidade?
                            </div>
                            <div className='d-flex justify-content-center mt-3'>
                                <Select
                                    value={targetSpot}
                                    multiple={true}
                                    ariaLabel='Board select'
                                    placeholder='Selecione a Unidade'
                                    onChange={handleMultiSetTargetSpot}>
                                    {spots?.map((spot) => (
                                        <Option key={spot.id} value={spot.id}>
                                            {spot.name}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </>
                    }

                    {spots.length <= 0 && reasonAccess === "owner" &&
                        <>
                            <div className='fw-bold fs-5 me-2 d-flex justify-content-center text-center'>
                                Nenhum Lote disponível para utilizar. Entre em contato com o suporte Entrada Segura.
                            </div>
                        </>
                    }
                </div>
            </CardBody>
        </Card>
    )
}