import { TableRow } from "./tableRow/table-row";
import { TableHeader } from "./tableHeader/table-header";
import { useEffect, useState } from "react";
import { User } from "../../../../../type/user";
import { getRegisterStatusText } from "../../constants/register-constants";
import { orderBy } from "lodash";
import { CardBody } from "../../../../../components/bootstrap/Card";

interface EditUserFormProps {
    userList: Array<User>;
    setShouldOpenEditUserForm: any;
    refreshTable: any
}

export const RegisterUserTable = ({ userList, setShouldOpenEditUserForm, refreshTable }: EditUserFormProps) => {

    const [lista, setLista] = useState<User[]>([]);
    const [ascOrderList, setAscOrderList] = useState(false);
    const [manualSort, setManualSort] = useState<boolean>(false);

    useEffect(() => {
        setLista(userList);
    }, [userList])

    function OrdenarLista() {
        setManualSort(true)
        if (ascOrderList) {
            setAscendentOrderList();
            setAscOrderList(false);
        }
        else {
            setDescendentOrderList();
            setAscOrderList(true);
        }
    }

    function setAscendentOrderList() {
        let AscendentList = [...lista].sort(function (a, b) {
            if (getRegisterStatusText(a.inquiryStatus!) > getRegisterStatusText(b.inquiryStatus!)) {
                return 1;
            }
            if (getRegisterStatusText(a.inquiryStatus!) < getRegisterStatusText(b.inquiryStatus!)) {
                return -1;
            }
            return 0;
        });
        setLista(AscendentList);
    }

    function setDescendentOrderList() {
        let DescendentList = [...lista].sort(function (a, b) {
            if (getRegisterStatusText(a.inquiryStatus!) < getRegisterStatusText(b.inquiryStatus!)) {
                return 1;
            }
            if (getRegisterStatusText(a.inquiryStatus!) > getRegisterStatusText(b.inquiryStatus!)) {
                return -1;
            }
            return 0;
        });
        setLista(DescendentList);
    }
    return (
        <CardBody className='table-responsive' isScrollable={true} style={{ height: '40rem' }}>
            {manualSort === false ?
                <table className='table table-modern table-responsive'>
                    <thead>
                        <TableHeader OrdenarLista={OrdenarLista} listAscOrder={ascOrderList} />
                    </thead>
                    <tbody>
                        {lista &&
                            orderBy(lista, ['inquiryStatus'], ['desc']).map((user: User) => {
                                return (
                                    <TableRow key={user.id} user={user} setShouldOpenEditUserForm={setShouldOpenEditUserForm} refreshTable={refreshTable} />
                                );
                            })
                        }
                    </tbody>
                </table>
                :
                <table className='table table-modern table-responsive'>
                    <thead>
                        <TableHeader OrdenarLista={OrdenarLista} listAscOrder={ascOrderList} />
                    </thead>
                    <tbody>
                        {lista &&
                            lista.map((user: User) => {
                                return (
                                    <TableRow key={user.id} user={user} setShouldOpenEditUserForm={setShouldOpenEditUserForm} refreshTable={refreshTable} />
                                );
                            })
                        }
                    </tbody>
                </table>
            }
        </CardBody>
    );
}