
import classNames from 'classnames';
import moment from 'moment';
import { useContext, useEffect, useState } from "react";
import Swal from 'sweetalert2';
import DefaultAvatar from '../../../../assets/img/wanna/wanna3.png';
import { UserContext } from '../../../../contexts/user-context';
import { formatCpf, phoneMask } from '../../../../helpers/helpers';
import useDarkMode from "../../../../hooks/useDarkMode";
import { getBoardingPassByTokenId } from '../../../../services/boarding-pass/boarding-pass-service';
import { getBuildingById } from '../../../../services/building/building-service';
import { getSpotById } from '../../../../services/spot/spot-service';
import { getUserDocumentsByUserId, getUserSelfieById } from '../../../../services/user/user-service';
import { Building } from '../../../../type/building';
import { Spot } from '../../../../type/spot';
import { Token } from '../../../../type/token';
import Avatar from '../../../Avatar';
import Button, { ButtonGroup } from '../../../bootstrap/Button';
import Popovers from '../../../bootstrap/Popovers';
import { TableColumn } from '../../consult-components/table-components/table-column';
import { handleSendBoardingPass } from '../../consult-components/token-table/token-row';

async function openAvatarModal(avatar: string) {
    Swal.fire({
        title: 'Selfie do usuário',
        html:
            `<img src="${avatar}" style="display: block; !important; margin-left: auto !important; margin-right: auto !important; max-width: 300px !important; max-height: 500px !important;" />`,
        showDenyButton: false,
        confirmButtonText: 'Ok',
        allowOutsideClick: true,
        backdrop: true
    });
}

function getRoleText(role: string | undefined) {
    switch (role) {
        case "visitor":
            return 'Visitante';
        case "family":
            return 'Familiar';
        case "party":
            return 'Festa';
        case "service":
            return 'Serviço';
        case "owner":
            return 'Proprietário';
        case "rent":
            return 'Aluguel';
        case "realtor":
            return 'Corretor';
        default:
            return 'Perfil não encontrado'
    }
}

interface InquirySendBPRowTokensProps {
    item: Token;
    setRefreshTable: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InquirySendBPRowTokens: React.FC<InquirySendBPRowTokensProps> = ({
    item,
    setRefreshTable,
}) => {
    const { darkModeStatus } = useDarkMode();
    const { user } = useContext(UserContext);

    const [avatar, setAvatar] = useState<string>(DefaultAvatar);
    const [building, setBuilding] = useState<Building>();
    const [spot, setSpot] = useState<Spot>();
    const [alreadyHasBoardingPass, setAlreadyHasBoardingPass] = useState<boolean>(false);
    const [serviceHasBackgroundAlready, setServiceHasBackgroundAlready] = useState<boolean>(true);

    useEffect(() => {
        if (item.userRequested.id)
            getUserSelfieById(item.userRequested.id, { setAvatar })
    }, [item.userRequested.id]);

    useEffect(() => {
        const getBuildingAndSpotNames = async () => {
            if (item.buildingId) {
                const buildingData = await getBuildingById(item.buildingId)
                setBuilding(buildingData)
            }

            if (item.spotId) {
                const spotData = await getSpotById(item.spotId)
                setSpot(spotData)
            }
        }

        getBuildingAndSpotNames()
    }, [item])

    useEffect(() => {
        const getUserRequestedData = async () => {
            const userData = await getUserDocumentsByUserId(item.userRequested.id)

            const userDocumentsBackgroundCheck = userData.filter((document) => document.documentType === "Background Check").map((document) => document.id);

            if (userDocumentsBackgroundCheck.length <= 0) {
                setServiceHasBackgroundAlready(false);
            }
        }

        if (item.userRequested.id) {
            getUserRequestedData()
        }
    }, [item])

    useEffect(() => {
        const getBP = async () => {
            if (item.id) {
                const alreadyHasBoardingPass = await getBoardingPassByTokenId(item.id);
                if (alreadyHasBoardingPass !== null)
                    setAlreadyHasBoardingPass(true)
            }
        }

        getBP()
    }, [item])

    return (
        <>
            <tr key={item.id}>
                <TableColumn>
                    <ButtonGroup>
                        <>
                            {alreadyHasBoardingPass === false &&
                                <Button
                                    isOutline={!darkModeStatus}
                                    color='dark'
                                    isLight={darkModeStatus}
                                    className={classNames('text-nowrap', {
                                        'border-light': !darkModeStatus,
                                    })}
                                    icon='Edit'
                                    style={{ marginRight: '5px' }}
                                    title={"Enviar BP"}
                                    onClick={() => handleSendBoardingPass({ tokenId: item.id, loggedUser: user.id, setRefreshTable, userIssuedForName: item.userRequested.name })}
                                >
                                    Enviar BP
                                </Button>
                            }
                        </>
                    </ButtonGroup>
                </TableColumn>
                <TableColumn>
                    {serviceHasBackgroundAlready === false && item.tag === "service" ?
                        <span style={{ color: '#E74C3C', fontWeight: 'bold' }} className='ms-3 d-flex align-items-center text-nowrap'>
                            <Popovers trigger='hover' desc='Este usuário com motivo Serviço ainda não enviou os documentos necessários para Background Check! Clique aqui para reenviar a solicitação de documentos.'>
                                {getRoleText(item.tag)}
                            </Popovers>
                        </span>
                        :
                        <span className='ms-3 d-flex align-items-center text-nowrap'>
                            {getRoleText(item.tag)}
                        </span>
                    }
                </TableColumn>
                <TableColumn>{<Avatar title={"Clique para visualizar a selfie"} onClick={() => openAvatarModal(avatar)} style={{ cursor: 'pointer' }} src={avatar} srcSet={avatar} color={'success'} size={40} />}</TableColumn>
                <TableColumn>{item.userRequested.name}</TableColumn>
                <TableColumn>{building?.name} | {spot?.name}</TableColumn>
                <TableColumn>{item.userIssued.name} | {moment(item.issuedAt).format("DD/MM/yy HH:mm")}</TableColumn>
                <TableColumn>{formatCpf(item.userRequested.taxid)}</TableColumn>
                <TableColumn>{phoneMask(item.userRequested.telephone)}</TableColumn>
            </tr>
        </>
    );
}