import { Document } from "../../type/document";
import api from "../api";

export async function create(params: Partial<Document>) {
    const response = await api.post(`/api/v1/userdocuments`, JSON.stringify(params), {
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    });
    return response.data;
}