import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../../components/bootstrap/Button';
import { CardBody, CardHeader } from '../../../components/bootstrap/Card';
import Input from '../../../components/bootstrap/forms/Input';
import { CreateOrEditUserForm } from '../../../components/entrada-segura/consult-components/edit-user-form';
import { USER_ROLES_LOWER } from '../../../components/entrada-segura/token/token-activation-form/blocks/constants';
import { UserContext } from '../../../contexts/user-context';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { RegisterUserTable } from './components/table/register-table';
import { INQUIRY_STATUS } from './constants/register-constants';
import './register-view.css';
import { getUserList, getUserListByBuildingId, getUserListFromSearchStringService } from './services/register.view.service';

export const RegisterView = () => {
	const { user } = useContext(UserContext);

	const activeUserId = user.id;
	const userHighestRole = localStorage.getItem("highest-role");

	const [stateModalRegister, setStateModalRegister] = useState<boolean>(false);
	const [userList, setUserList] = useState<Array<any>>([]);
	const location = useLocation();
	const [searchString, setSearchString] = useState<string>("");
	const [ascOrderList, setAscOrderList] = useState(true);
	const [modalCreateOrEditUserVisible, setModalCreateOrEditUserVisible] = useState<boolean>(false);
	const [userToEdit, setUserToEdit] = useState(0);
	const [refreshTable, setRefreshTable] = useState<boolean>(false);

	const navigate = useNavigate();

	useEffect(() => {
		if (refreshTable) {
			getUsers();
			setRefreshTable(false);
		}
	}, [refreshTable]);

	useEffect(() => {
		getUsers();

		//Handle edit user from Controle de Acessos
		if (location.state != null) {
			const { userId } = location.state as any;
			openModalEditUser(userId);
			window.history.replaceState({}, document.title)
		}
	}, []);


	async function getUsers() {
		const response = userHighestRole == USER_ROLES_LOWER.CONTRACT_MANAGER || userHighestRole == USER_ROLES_LOWER.STAFF 
			? await getUserListByBuildingId(activeUserId) 
			: await getUserList();
		if (userHighestRole == USER_ROLES_LOWER.CONTRACT_MANAGER || userHighestRole == USER_ROLES_LOWER.STAFF) {
			let lista = response.filter((user: any) => user.inquiryStatus != undefined && user.inquiryStatus != null);
			setUserList(lista);
		}
		else {
			setUserList(response);
		}
	}

	async function getUserListFromSearchString(searchString: string) {
		let response: any = "";
		if (searchString === "") {
			response = userHighestRole === USER_ROLES_LOWER.CONTRACT_MANAGER || userHighestRole === USER_ROLES_LOWER.STAFF 
				? await getUserListByBuildingId(activeUserId) 
				: await getUserList();
			setUserList(response);
		}
		else if (searchString.length > 0 && searchString.length < 3)
			return
		else {
			response = userHighestRole === USER_ROLES_LOWER.CONTRACT_MANAGER || userHighestRole === USER_ROLES_LOWER.STAFF 
				? await getUserListByBuildingId(activeUserId).then((response) => response.filter((user) => user.name.toLowerCase().includes(searchString.toLowerCase())))
				: await getUserListFromSearchStringService(searchString)
			setUserList(response);
		}
	}

	const handleSearchStringChange = (e: { target: { value: string; }; }) => {
		setSearchString(e.target.value);
		getUserListFromSearchString(e.target.value);
	};

	function openModalEditUser(idUser: number) {
		setUserToEdit(idUser);
		setModalCreateOrEditUserVisible(true);
	}

	const handleRegisterUserModal = () => {
		setUserToEdit(0);
		setModalCreateOrEditUserVisible(!modalCreateOrEditUserVisible)
	}
	return (
		<PageWrapper title={'Cadastro'}>
			<Page container='fluid'>
				<div className='fw-bold fs-5' style={{ display: 'flex', justifyContent: 'center' }}>
					Cadastro de Usuário
				</div>
				<CardHeader>
					<div style={{ width: '100%' }} className='header-cadastro d-flex justify-content-between'>
						<Input
							className='search-input'
							onChange={handleSearchStringChange}
							type='text'
							placeholder='Pesquisar...'
							style={{
								display: 'flex',
								justifyContent: 'center',
								maxWidth: '480px',
								backgroundColor: 'transparent!important',
								borderColor: 'transparent!important',
							}}
							value={searchString}
						/>
					</div>

				</CardHeader>
				<CardBody style={{ display: 'flex', justifyContent: 'center' }} className='table-responsive'>
					<RegisterUserTable userList={userList} setShouldOpenEditUserForm={openModalEditUser} refreshTable={refreshTable} />
				</CardBody>
			</Page>
			<CreateOrEditUserForm
				userId={userToEdit}
				setShouldOpenEditUserForm={setModalCreateOrEditUserVisible}
				shouldOpenEditUserForm={modalCreateOrEditUserVisible}
				setRefreshTable={setRefreshTable}
			/>

		</PageWrapper >

	);
};