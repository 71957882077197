import { ChangeEvent, useContext, useEffect, useState } from "react";
import { Token } from "../../../type/token";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../bootstrap/Modal"
import moment from "moment";
import { CardBody } from "../../bootstrap/Card";
import Input from "../../bootstrap/forms/Input";
import Button from "../../bootstrap/Button";
import { updateTokenById } from "../../../services/token/token-service";
import showNotification from "../../extras/showNotification";
import { UserContext } from "../../../contexts/user-context";
import Swal from "sweetalert2";

type EditTokenFormProps = {
    token: Token | null;
    setIsOpenEditTokenForm: (isOpen: boolean) => void;
    isOpenEditTokenForm: boolean;
    setRefreshTable: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EditTokenForm = ({ token, isOpenEditTokenForm, setIsOpenEditTokenForm, setRefreshTable }: EditTokenFormProps) => {
	const { user } = useContext(UserContext);
    const [expiresAtDateTime, setExpiresAtDateTime] = useState<string>(moment(token?.expiresAt).format('YYYY-MM-DD'));
    const [isSaving, setIsSaving] = useState<boolean>(false);
    
    useEffect(() => {
        if (token) {
            setExpiresAtDateTime(moment(token.expiresAt).format('YYYY-MM-DD'));
        }
    }, [token])

    const onChangeExpireAtDateTime = (event: ChangeEvent<HTMLInputElement>) => {
        const newDate = moment(event.currentTarget.value).format('YYYY-MM-DD');
        setExpiresAtDateTime(newDate);
    }

    const handleSave = async () => {
        if (!token) {
            showNotification('Erro ao salvar as alterações', 'Token não encontrado', 'error');
            return;
        }

        setIsSaving(true);
        
        Swal.fire({
            title: 'ATENÇÃO',
            html:
                '<span class"fs-3">Você confirma a alteração?</span><br/><br/>' +
                '<ul class="fs-5" style="text-align: left!important;">' +
                `<li>${token.userRequested !== null ? token.userRequested.name.toUpperCase() : "Usuário sem nome"}</li>` +
                '<li>Alteração de data de expiração para: <strong>' + moment(expiresAtDateTime).format('DD/MM/YYYY') + '</strong></li>' +
                '</ul>',
            customClass: {
                container: 'bg-dark',
                htmlContainer: 'text-left',
                confirmButton: 'bg-info',
            },
            showDenyButton: true,
            confirmButtonText: 'Confirmar',
            allowOutsideClick: false,
            denyButtonText: `Cancelar`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                showNotification(`Salvando alterações`, `Aguarde um momento`, 'info');

                const expiresAtWithoutTimezone = moment(expiresAtDateTime).endOf('day').format('YYYY-MM-DD[T]HH:mm:ss+00:00');

                await updateTokenById({
                    tokenId: token.id,
                    modifiedAt: moment().toISOString(),
                    modifiedBy: user.id,
                    reasonAccess: token.tag || null,
                    expiresAt: expiresAtWithoutTimezone,
                })

                showNotification(`Alterações salvas com sucesso`, ``, 'success');
                setRefreshTable((prev) => !prev);
                setIsOpenEditTokenForm(false);
            } else {
                showNotification(`Ação cancelada pelo usuário`, ``, 'warning');
            }
        }).catch((error) => {
            showNotification(`Erro ao salvar as alterações`, ``, 'error');
        }).finally(() => {
            setIsSaving(false);
        })
    }

    return (
        <Modal
            setIsOpen={setIsOpenEditTokenForm}
            isOpen={isOpenEditTokenForm}
            titleId='edit-token-form'
            isScrollable
            isStaticBackdrop
            isCentered
            size={'lg'}
        >
            <ModalHeader setIsOpen={setIsOpenEditTokenForm}>
                <ModalTitle id='edit-token-form'>
                    Editar Acesso de {token?.userRequested?.name}
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                <div>
                    <CardBody isScrollable={false}>
                        <div>
                            <h4>Data Fim</h4>
                            <Input
                                onChange={onChangeExpireAtDateTime}
                                value={expiresAtDateTime}
                                type='date'
                                style={{
                                    backgroundColor: 'transparent!important',
                                    borderColor: 'transparent!important',
                                }}
                            />
                        </div>

                        <div className='d-flex justify-content-end mt-3'>
                            <Button
                                color='info'
                                icon='Save'
                                onClick={() => handleSave()}
                                isDisable={isSaving}
                            >
                                Salvar
                            </Button>
                        </div>
                    </CardBody>
                </div>
            </ModalBody>
        </Modal>
    )
}